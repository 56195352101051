'use client'

import { Message, useChat } from 'ai/react'
import { useSession } from 'next-auth/react'
import { useTranslations } from 'next-intl'
import { useEffect, useMemo, useRef, useState } from 'react'

import { useChatbot } from '@/providers/ChatbotProvider'

import ChatAvatar from '../Chat/ChatAvatar'
import ChatInput from '../Chat/ChatInput'
import { useClientConfig } from '../Chat/useConfig'
import AvaConversationState from './AvaConversationState'

const GenerateSchoolListState = () => {
  const t = useTranslations('AskAva')

  const contentDivRef = useRef<HTMLDivElement>(null)
  const { update, data: session } = useSession()

  const hasReachedLimit = useMemo(() => session?.user.aiRateLimited, [session?.user.aiRateLimited])
  const { isSchoolListInitialTextGenerated, setIsInitialSchoolListGenerated, schoolListChatId } = useChatbot()

  const [history, setHistory] = useState<Message[]>([])

  const { chatAPI } = useClientConfig()

  const { messages, input, isLoading, handleSubmit, handleInputChange, reload, stop, append, setInput } = useChat({
    api: `${chatAPI}/stream/${schoolListChatId}`,
    id: schoolListChatId,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${session?.token}`,
    },
    onError: (error: unknown) => {
      if (!(error instanceof Error)) throw error
      console.log(error)
      const message = JSON.parse(error.message)
      console.error(message.detail)
    },
    onFinish: async (message, { usage }) => {
      // `usage` object is documented here: https://sdk.vercel.ai/docs/reference/ai-sdk-ui/use-chat
      // - POST update to /api/chatbot/usage
      // - update session
      const response = await fetch('/api/chatbot/usage', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(usage),
      })
      if (response.ok) {
        const body = await response.json()
        await update(body)
      }
    },
    initialMessages: history,
  })

  const speed = 5

  const initialMessages = [
    t('firstSchoolListInitialMessage'),
    t('secondSchoolListInitialMessage'),
    t('thirdSchoolListInitialMessage'),
    t('fourthSchoolListInitialMessage'),
  ] // Array of messages

  const [displayedMessages, setDisplayedMessages] = useState<string[]>([])
  const [messageIndex, setMessageIndex] = useState(0)
  const [charIndex, setCharIndex] = useState(0)

  useEffect(() => {
    if (isSchoolListInitialTextGenerated) {
      setDisplayedMessages(initialMessages)
      return
    }

    if (messageIndex === initialMessages.length) {
      append?.({
        role: 'user',
        content: t('automaticFollowUpMessage'),
      })
      setIsInitialSchoolListGenerated(true)
    }

    const currentMessage = initialMessages[messageIndex]

    if (messageIndex < initialMessages.length) {
      const timeout = setTimeout(() => {
        setDisplayedMessages((prevMessages) => {
          const updatedMessages = [...prevMessages]
          const updatedMessage = currentMessage.slice(0, charIndex + 1)
          updatedMessages[messageIndex] = updatedMessage
          return updatedMessages
        })

        setCharIndex((prev) => prev + 1)

        if (charIndex + 1 === currentMessage.length) {
          setMessageIndex((prev) => prev + 1)
          setCharIndex(0)
        }
      }, speed)

      return () => clearTimeout(timeout)
    }
  }, [charIndex])

  return (
    <>
      {!hasReachedLimit && (
        <div className="flex pb-5 flex-col w-full h-full">
          <div ref={contentDivRef} className="flex-grow overflow-y-auto pt-4 flex flex-col w-full  relative">
            <div className="flex gap-x-4 px-5 md:px-10">
              {' '}
              <ChatAvatar role={'assistant'} />
              <div className="flex flex-col pr-5">
                {displayedMessages.map((message, idx) => (
                  <p key={idx} className="mb-2 last:mb-0">
                    {message}
                  </p>
                ))}
              </div>
            </div>
            {messages.length > 0 && (
              <AvaConversationState
                chatId={schoolListChatId || ''}
                messages={messages.slice(1)}
                reload={reload}
                append={append}
                stop={stop}
                isLoading={isLoading}
                isSchoolList={true}
              />
            )}
          </div>
          <div className="inset-x-0 px-5 md:px-10">
            <ChatInput
              placeholder={t('schoolListPromptPlaceholder')}
              input={input}
              handleInputChange={(e) => {
                handleInputChange(e)
              }}
              handleSubmit={(e) => {
                handleSubmit(e)
                setIsInitialSchoolListGenerated(true)
              }}
              isLoading={isLoading}
              messages={messages}
              append={append}
              setInput={setInput}
              padding={4}
              isSchoolList={true}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default GenerateSchoolListState
