'use client'

import {
  ArrowPathIcon,
  ClipboardIcon,
  DocumentDuplicateIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
} from '@heroicons/react/24/outline'
import {
  HandThumbDownIcon as HandThumbDownIconSolid,
  HandThumbUpIcon as HandThumbUpIconSolid,
} from '@heroicons/react/24/solid'
import { Button, Tooltip } from '@material-tailwind/react'
import { Message } from 'ai'
import { useTranslations } from 'next-intl'
import { useState } from 'react'

import ChatMessages from '../Chat/ChatMessages'

interface Props {
  chatId: string
  messages: Message[]
  isLoading: boolean
  isSchoolList?: boolean
  reload?: (() => void) | undefined
  stop?: (() => void) | undefined
  append?:
    | ((
        message: Message | Omit<Message, 'id'>,
        ops?: {
          data: any
        }
      ) => Promise<string | null | undefined>)
    | undefined
}
const AvaConversationState = ({ messages, isLoading, reload, stop, append, chatId, isSchoolList }: Props) => {
  const [showFeedback, setShowFeedback] = useState(false)
  const [feedbackType, setFeedbackType] = useState('')
  const [feedbackComment, setFeedbackComment] = useState('')
  const [copySuccess, setCopySuccess] = useState(false)
  const [activeFeedback, setActiveFeedback] = useState('')
  const [feedbackMessage, setFeedbackMessage] = useState<undefined | string>(undefined)
  const t = useTranslations('AskAva')

  const handleFeedbackClick = (type: string) => {
    setFeedbackType(type)
    setShowFeedback(true)
    setActiveFeedback(type)
  }

  const handleFeedbackSubmit = () => {
    // Handle feedback submission logic here

    fetch(`/api/chatbot/feedback`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        chatbotSessionId: chatId,
        feedback: feedbackType,
        comments: feedbackComment,
      }),
    })
      .then(() => {
        setFeedbackMessage(t('feedbackSubmitSuccess'))
      })
      .catch(() => {
        setFeedbackMessage(t('feedbackSubmitError'))
      })

    setTimeout(() => {
      setShowFeedback(false)
      setFeedbackComment('')
      setFeedbackMessage(undefined)
      setActiveFeedback('')
    }, 2000)
  }

  const handleCopyToClipboard = () => {
    const textToCopy = messages[messages.length - 1].content

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopySuccess(true)
        setTimeout(() => setCopySuccess(false), 2000) // Hide success message after 2 seconds
      })
      .catch((err) => console.error('Failed to copy text: ', err))
  }

  const handleCopyAllToClipboard = () => {
    const textToCopy = messages
      .map((msg) => {
        const role = msg.role === 'assistant' ? 'Eva' : msg.role
        return `${role}: ${msg.content}`
      })
      .join('\n\n')

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopySuccess(true)
        setTimeout(() => setCopySuccess(false), 3000) // Hide success message after 2 seconds
      })
      .catch((err) => console.error('Failed to copy text: ', err))
  }

  const FeedbackIcons: React.FC<{ isSchoolList: boolean }> = ({ isSchoolList }) => {
    const marginClass = isSchoolList ? 'mb-4' : 'my-4'

    return (
      <div className={`flex space-x-2 ${marginClass} ml-10`}>
        <button
          className="relative group hover:shadow-none shadow-none rounded-full"
          onClick={() => handleFeedbackClick('positive')}
        >
          <Tooltip content={t('goodResponse')} position="top" className="py-2">
            {activeFeedback === 'thumbs-up' ? (
              <HandThumbUpIconSolid className="w-6 h-6 text-gray-500 " />
            ) : (
              <HandThumbUpIcon className="w-6 h-6 text-gray-500 " />
            )}
          </Tooltip>
        </button>
        <button className="relative group" onClick={() => handleFeedbackClick('negative')}>
          <Tooltip content={t('badResponse')} position="top" className="py-2">
            {activeFeedback === 'thumbs-down' ? (
              <HandThumbDownIconSolid className="w-6 h-6 text-gray-500 " />
            ) : (
              <HandThumbDownIcon className="w-6 h-6 text-gray-500 " />
            )}
          </Tooltip>
        </button>
        <button className="relative group" onClick={() => handleCopyAllToClipboard()}>
          <Tooltip content={t('copyAll')} position="top" className="py-2">
            <DocumentDuplicateIcon className="w-6 h-6 text-gray-500 " />
          </Tooltip>
        </button>
        <button className="relative group" onClick={() => handleCopyToClipboard()}>
          <Tooltip content={t('copy')} position="top" className="py-2">
            <ClipboardIcon className="w-6 h-6 text-gray-500 " />
          </Tooltip>
        </button>
        <button className="relative group" onClick={() => reload && reload()}>
          <Tooltip content={t('regenerate')} position="top" className="py-2">
            <ArrowPathIcon className="w-6 h-6 text-gray-500 " />
          </Tooltip>
        </button>
      </div>
    )
  }

  return (
    <>
      <ChatMessages
        messages={messages}
        isLoading={isLoading}
        reload={reload}
        stop={stop}
        append={append}
        isSchoolList={isSchoolList}
      />
      {!isLoading &&
        (isSchoolList ? (
          <div className="px-5 md:px-10">
            <FeedbackIcons isSchoolList={isSchoolList} />
          </div>
        ) : (
          <FeedbackIcons isSchoolList={isSchoolList ?? false} />
        ))}

      {/* Copy success message */}
      {copySuccess && (
        <div className={`${isSchoolList ? 'px-5 md:px-10' : 'mt-2'} ml-10 text-green-600 text-sm`}>
          {t('avaChatCopied')}
        </div>
      )}

      {/* Feedback textbox */}
      {showFeedback && (
        <div className={`${isSchoolList ? 'px-5 md:px-10' : 'mt-4'} ml-10`}>
          <p className="text-sm mb-2">{t('feedbackQuestion')}</p>
          <textarea
            className="w-full md:w-3/4 p-2 border min-h-24 border-gray-400 rounded-lg bg-brand-tan
                     focus:border-gray-400 focus:shadow-none text-sm"
            placeholder={t('feedbackPlaceholder')}
            value={feedbackComment}
            onChange={(e) => setFeedbackComment(e.target.value)}
          />
          {feedbackMessage && (
            <div>
              <p className="text-sm mb-2 text-green-600">{feedbackMessage}</p>
            </div>
          )}
          <div>
            <Button
              className="py-3 px-4 bg-white text-gray-900 border-gray-900 rounded-lg text-sm font-bold"
              onClick={handleFeedbackSubmit}
            >
              {t('submit')}
            </Button>
          </div>
        </div>
      )}
    </>
  )
}

export default AvaConversationState
