'use client'

import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { Button, Spinner, Tooltip } from '@material-tailwind/react'
import { Message, useChat } from 'ai/react'
import { useSession } from 'next-auth/react'
import { useTranslations } from 'next-intl'
import Image from 'next/image'
import { useSearchParams } from 'next/navigation'
import { useEffect, useMemo, useRef, useState } from 'react'

import { useAvaSidebar } from '@/providers/AvaSidebarProvider'
import { useChatbot } from '@/providers/ChatbotProvider'
import { useRouterHistoryContext } from '@/providers/RouterHistoryProvider'
import avaIcon from '@/public/images/ava-icon.png'

import { UserRoles } from '@roadmap/lib/services/firebase/firestore/enums'
import { StarterQuestionRecord } from '@roadmap/lib/types'
import { getPeriodStringForDate } from '@roadmap/lib/utils/dates'

import ChatInput from '../Chat/ChatInput'
import { useClientConfig } from '../Chat/useConfig'
import AutowelcomeMessage from './AutowelcomeMessage'
import AvaConversationState from './AvaConversationState'
import AvaInitialState from './AvaInitialState'
import GenerateSchoolListState from './GenerateSchoolListState'
import ReachedLimitState from './ReachedLimitState'
import RecLetterResponseState from './RecLetterComponents/RecLetterResponseState'
import RecLetterState from './RecLetterState'

const AvaSidebar = () => {
  const { update, data: session } = useSession()
  const { isOpen, shouldShowWelcomeMessage, toggleSidebar, closeSidebar } = useAvaSidebar()
  const [sidebarWidth, setSidebarWidth] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const sidebarRef = useRef<HTMLDivElement>(null)
  const t = useTranslations('AskAva')
  const contentDivRef = useRef<HTMLDivElement>(null)
  const hasReachedLimit = useMemo(() => {
    return session?.user.aiRateLimited
  }, [session?.user.aiRateLimited])

  const [history, setHistory] = useState<Message[]>([])
  const { data } = useSession()
  const userRole = useMemo(() => {
    return data?.user.role
  }, [data])

  const queryParams = useSearchParams()
  const welcomeParam = queryParams.get('welcome')

  const {
    chatId,
    initialPrompt,
    refreshChat,
    isLoading: isChatLoading,
    setInitialPrompt,
    breadcrumbs,
    setBreadcrumbs,
    selectedBreadcrumbIndex,
    resetInputValue,
    isSchoolListInitialTextGenerated,
    setIsInitialSchoolListGenerated,
  } = useChatbot()

  const { chatAPI } = useClientConfig()
  const [isCardClicked, setIsCardClicked] = useState<boolean>(false)
  const [width, setWidth] = useState(0)
  const [isContentGenerated, setIsContentgenerated] = useState<boolean | null>(null)
  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth
      setWidth(newWidth)
    }
    window.addEventListener('resize', updateWindowDimensions)
    return () => window.removeEventListener('resize', updateWindowDimensions)
  }, [])

  useEffect(() => {
    if (sidebarRef.current) {
      setSidebarWidth(sidebarRef.current.getBoundingClientRect().width)
    }
  }, [isOpen, width])

  // While changing states of breadcrumbs, it keeps position of scroll,
  // so I've added this to always scroll to top when breadcrumbs change.
  useEffect(() => {
    if (contentDivRef.current) {
      if (breadcrumbs[breadcrumbs.length - 1] !== 'conversation')
        contentDivRef.current.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [breadcrumbs])

  const { messages, input, isLoading, handleSubmit, handleInputChange, reload, stop, append, setInput } = useChat({
    api: `${chatAPI}/stream/${chatId}`,
    id: chatId,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${session?.token}`,
    },
    onError: (error: unknown) => {
      if (!(error instanceof Error)) throw error
      console.log(error)
      const message = JSON.parse(error.message)
      console.error(message.detail)
    },
    onFinish: async (message, { usage }) => {
      // `usage` object is documented here: https://sdk.vercel.ai/docs/reference/ai-sdk-ui/use-chat
      // - POST update to /api/chatbot/usage
      // - update session
      const response = await fetch('/api/chatbot/usage', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(usage),
      })
      if (response.ok) {
        const body = await response.json()
        await update(body)
      }
    },
    initialMessages: history,
  })
  useEffect(() => {
    if (!!initialPrompt) {
      toggleSidebar(false)
      if (!isChatLoading && !hasReachedLimit) {
        setInput(initialPrompt)
        setIsCardClicked(true)
        setInitialPrompt('')
      }
    }
  }, [hasReachedLimit, initialPrompt, isChatLoading, setInitialPrompt, setInput, toggleSidebar])

  const [randomQuestions, setRandomQuestions] = useState<StarterQuestionRecord[]>([])

  const period = getPeriodStringForDate(data?.user.graduationYear || new Date().getFullYear() + 1)

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        if (data?.user.role) {
          const response = await fetch('/api/starter-questions', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ role: data?.user.role, pathways: data?.user.pathways, period: period }),
          })

          if (!response.ok) {
            throw new Error(`Error: ${response.status} - ${response.statusText}`)
          }

          const json = await response.json()

          if (json.status === 'error') {
            console.error(json.error)
          } else {
            setRandomQuestions(json.content)
          }
        }
      } catch (error) {
        console.error('Failed to fetch starter questions:', error)
      } finally {
      }
    }

    fetchQuestions()
  }, [data])

  const refreshAvaContent = () => {
    refreshChat()
    if (selectedBreadcrumbIndex) selectedBreadcrumbIndex.current = 0
    setBreadcrumbs(['home'])
    setHistory([])
  }

  const handleCloseSidebar = () => {
    if (messages.length > 0 || isSchoolListInitialTextGenerated) setShowModal(true)
    else closeSidebar()
  }
  const confirmClose = (confirm: boolean) => {
    if (confirm) {
      closeSidebar()
      refreshAvaContent()
    }
    setShowModal(false)
  }

  const content = useMemo(() => {
    const currentBreadcrumb = selectedBreadcrumbIndex?.current
    if (currentBreadcrumb === undefined) return
    switch (breadcrumbs[currentBreadcrumb]) {
      case 'conversation':
        return hasReachedLimit ? (
          <ReachedLimitState />
        ) : (
          <AvaConversationState
            chatId={chatId || ''}
            messages={messages}
            reload={reload}
            append={append}
            stop={stop}
            isLoading={isLoading}
          />
        )
      case 'compose':
        return <RecLetterState />
      case 'generate':
        return <RecLetterResponseState />
      case 'schoolList':
        return <GenerateSchoolListState />
      default:
        return hasReachedLimit ? (
          <ReachedLimitState />
        ) : (
          <AvaInitialState
            onCardSelected={(cardContent) => {
              setInput(cardContent)
              setIsCardClicked(true)
            }}
            isOpen={isOpen}
            setGenerationComplete={setIsContentgenerated}
            generationComplete={isContentGenerated}
            generatedCards={randomQuestions}
          />
        )
    }
  }, [
    append,
    breadcrumbs,
    chatId,
    hasReachedLimit,
    isLoading,
    messages,
    reload,
    selectedBreadcrumbIndex,
    setInput,
    stop,
  ])

  // Handling on prompt card click
  useEffect(() => {
    if (isCardClicked && !isChatLoading && !hasReachedLimit) {
      handleSubmit()
      setIsCardClicked(false)
      if (breadcrumbs.includes('conversation')) {
        if (selectedBreadcrumbIndex)
          selectedBreadcrumbIndex.current = breadcrumbs.findIndex((el) => el === 'conversation')
      } else {
        if (selectedBreadcrumbIndex) selectedBreadcrumbIndex.current = breadcrumbs.length
        setBreadcrumbs([...breadcrumbs, 'conversation'])
      }
    }
  }, [
    isCardClicked,
    isChatLoading,
    hasReachedLimit,
    handleSubmit,
    setBreadcrumbs,
    breadcrumbs,
    selectedBreadcrumbIndex,
  ])

  const { previousPath } = useRouterHistoryContext()

  const removeQueryParam = (param: string) => {
    const url = new URL(window.location.href)
    url.searchParams.delete(param)
    window.history.replaceState(null, '', url.toString())
  }

  useEffect(() => {
    if (welcomeParam === 'true') {
      toggleSidebar(true)
      removeQueryParam('welcome')
    }
  }, [welcomeParam])

  useEffect(() => {
    if (
      (previousPath?.includes('/onboarding') || previousPath?.includes('/activate')) &&
      shouldShowWelcomeMessage === undefined &&
      !hasReachedLimit
    ) {
      toggleSidebar(true)
    }
  }, [previousPath, hasReachedLimit, shouldShowWelcomeMessage, toggleSidebar])

  const handleClickOnComposeARecommendationLetter = () => {
    if (selectedBreadcrumbIndex) selectedBreadcrumbIndex.current = breadcrumbs.length
    setBreadcrumbs([...breadcrumbs, 'compose'])
  }

  const handleClickOnGenerateASchoolList = () => {
    if (selectedBreadcrumbIndex) selectedBreadcrumbIndex.current = breadcrumbs.length
    setBreadcrumbs([...breadcrumbs, 'schoolList'])
  }

  return (
    <div>
      <button
        id="avaSidebar"
        onClick={() => (isOpen ? closeSidebar() : toggleSidebar())}
        className="fixed top-1/3 z-50 transition-all duration-300  px-6 py-2 flex flex-col items-center rounded-l-2xl 
                  bg-brand-gold cursor-pointer"
        style={{ right: isOpen ? `${sidebarWidth}px` : '0px' }}
      >
        <div className="h-9 w-6 relative">
          <Image
            src={avaIcon}
            alt="Ava Icon"
            fill
            style={{ objectFit: 'contain' }}
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 100vw, 100vw"
          />
        </div>
        <span className="text-xs font-bold uppercase mt-0.5">Eva</span>
      </button>
      {/* Sidebar */}
      <div
        ref={sidebarRef}
        className={`fixed pb-5 top-0 right-0 w-full md:w-4/6 lg:w-3/5 xl:w-1/2 2xl:w-5/12 h-screen
          bg-brand-tan text-gray-900 transform ${
            isOpen ? 'translate-x-0' : 'translate-x-full'
          } transition-transform duration-300 z-40 flex flex-col`}
      >
        <div className="flex items-center justify-between p-3 bg-brand-purple-800">
          <div className="flex items-center space-x-2">
            <div className="relative w-[22px] h-[36px]">
              <Image
                src={avaIcon}
                alt="Ava Icon"
                fill
                style={{ objectFit: 'contain' }}
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 100vwvw, 100vw"
              />
            </div>
            <h2 className="text-xl font-bold text-white px-2">{t('askAva')}</h2>
          </div>
          <Tooltip content={t('closeTooltip')} placement="bottom">
            <Button
              onClick={handleCloseSidebar}
              className="p-2 font-bold text-white cursor-pointer bg-transparent hover:shadow-none shadow-none rounded-full"
            >
              <XMarkIcon className="h-7 w-7" />
            </Button>
          </Tooltip>
        </div>
        {/** Breadcrumbs */}
        <div className="bg-brand-purple-800 opacity-90 py-2 px-4 flex flex-row">
          {breadcrumbs.map((element, index) => {
            return (
              <div className="flex flex-row items-center" key={element}>
                <button
                  className={`text-white block ${selectedBreadcrumbIndex?.current === index && 'underline'}`}
                  onClick={() => {
                    if (selectedBreadcrumbIndex) selectedBreadcrumbIndex.current = index
                    // Removing all breadcrumbs after the one that is selected
                    const newBreadcrumbs =
                      index === 0 && breadcrumbs.includes('conversation')
                        ? breadcrumbs.slice(0, index + 2)
                        : breadcrumbs.slice(0, index + 1)

                    setBreadcrumbs(newBreadcrumbs)
                  }}
                >
                  <p>{t(element)}</p>
                </button>
                {index < breadcrumbs.length - 1 && <ChevronRightIcon className="fill-white size-4 mx-2" />}
              </div>
            )
          })}
        </div>

        {/** Content */}

        <div
          ref={contentDivRef}
          className={`flex-grow overflow-y-auto ${breadcrumbs[selectedBreadcrumbIndex?.current ?? -1] === 'schoolList' ? 'py-2 md:py-4' : 'p-2 md:p-4'}`}
        >
          <div
            className={`w-full h-full ${breadcrumbs[selectedBreadcrumbIndex?.current ?? -1] === 'schoolList' ? '' : 'px-3 md:px-6'}`}
          >
            {shouldShowWelcomeMessage ? (
              <AutowelcomeMessage onLetsStartClick={() => closeSidebar()} />
            ) : isChatLoading ? (
              <div className="w-full h-full min-h-full flex justify-center items-center">
                <Spinner />
              </div>
            ) : (
              <div
                className={`pb-4 ${breadcrumbs[selectedBreadcrumbIndex?.current ?? -1] === 'schoolList' ? 'h-full' : ''}`}
              >
                {content}
              </div>
            )}
          </div>
        </div>

        {/* Input should be shown only on Home and Conversations cases. */}
        {(breadcrumbs[breadcrumbs.length - 1] === 'home' || breadcrumbs[breadcrumbs.length - 1] === 'conversation') &&
          !hasReachedLimit && (
            <>
              <ChatInput
                placeholder={t('askAvaPromptPlaceholder')}
                input={input}
                handleInputChange={(e) => {
                  handleInputChange(e)
                }}
                handleSubmit={(e) => {
                  handleSubmit(e)

                  if (breadcrumbs[breadcrumbs.length - 1] === 'home') {
                    if (selectedBreadcrumbIndex) selectedBreadcrumbIndex.current = breadcrumbs.length
                    setBreadcrumbs([...breadcrumbs, 'conversation'])
                  }
                }}
                isLoading={isLoading}
                messages={messages}
                append={append}
                setInput={setInput}
                padding={4}
              />
              {breadcrumbs[selectedBreadcrumbIndex?.current ?? -1] !== 'schoolList' && (
                <div className="flex flex-col sm:flex-row px-5 md:px-10 gap-2 sm:gap-4 mb-6 py-3">
                  {userRole === UserRoles.COUNSELOR && (
                    <div className="md:pr-0 ">
                      <button
                        className="py-1 px-2 text-white bg-brand-purple rounded-3xl p-2 cursor-pointer 
                                  shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 
                                  focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none 
                                  w-auto font-sans text-base"
                        onClick={handleClickOnComposeARecommendationLetter}
                      >
                        <p>{t('composeRecLetter')}</p>
                      </button>
                    </div>
                  )}
                  <div className="">
                    <button
                      className="py-1 px-2 text-white bg-brand-purple rounded-3xl p-2 cursor-pointer 
                                shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 
                                focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none 
                                w-auto font-sans text-base"
                      onClick={handleClickOnGenerateASchoolList}
                    >
                      <p>{t('generateSchoolList')}</p>
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
      </div>
      {/* Overlay */}
      {isOpen && <div onClick={() => closeSidebar()} className="fixed inset-0 bg-black opacity-20 z-30" />}
      {/* Confirmation Modal */}
      {showModal && (
        <div className="fixed inset-0 top-24 flex items-start justify-center z-50">
          <div className="fixed inset-0 bg-black opacity-30 z-50"></div>
          <div
            className="bg-white rounded-lg shadow-lg mx-2 p-3 md:p-6 max-w-sm md:max-w-lg w-full z-50"
            onClick={(e) => e.stopPropagation()}
          >
            <h3 className="text-lg font-semibold mb-4">{t('confirmation')}</h3>
            <p className="text-gray-900 mb-4">
              <span className="text-red-500">{t('closingAvaPanelHistory')}</span>
              <br />
              {t('closingAvaPanelCopy')}
              <br />
              {t('useAvaSessionLater')}
            </p>
            <div className="flex justify-end space-x-4">
              <Button
                onClick={() => confirmClose(false)}
                className="px-4 py-4 bg-white border-gray-800 text-gray-900 rounded-lg"
              >
                {t('cancel')}
              </Button>
              <Button
                onClick={() => {
                  resetInputValue()
                  confirmClose(true)
                  setIsInitialSchoolListGenerated(false)
                }}
                className="px-4 py-4 bg-brand-purple-700 text-white rounded-lg"
              >
                {t('closeAvaPanel')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default AvaSidebar
