import { Message } from 'ai'
import { useSession } from 'next-auth/react'
import { Fragment, useEffect, useRef, useState } from 'react'

import { MessageAnnotation, MessageAnnotationType, SourceData, getAnnotationData } from '../ui/chat'
import ChatFollowUpQuestions from '../ui/chat/chat-follow-up-questions'
import { ChatTabSources } from '../ui/chat/chat-tab-sources'
import ChatAvatar from './ChatAvatar'
import Markdown from './Markdown'

type ContentDisplayConfig = {
  order: number
  component: JSX.Element | null
}

const ChatMessageContent = ({ message, isLoading }: { message: Message; isLoading: boolean }) => {
  const [messageContentWithoutFollowUps, setMessageContentWithoutFollowUps] = useState<string>(message.content)

  const [followupQuestions, setFollowupQuestions] = useState<string[] | null>(null)

  useEffect(() => {
    const index = message.content.indexOf('{')
    if (index !== -1) {
      const textBeforeJSON = message.content.substring(0, index).trim()
      const jsonString = message.content.substring(index).trim()

      setMessageContentWithoutFollowUps(textBeforeJSON)

      try {
        if (jsonString.endsWith('}')) {
          // Simple check to avoid incomplete JSON parsing
          const parsedData = JSON.parse(jsonString)

          if (Array.isArray(parsedData.followup_questions)) {
            setFollowupQuestions(parsedData.followup_questions)
          }
        } else {
          console.warn('Potentially incomplete JSON string, skipping parse:', jsonString)
        }
      } catch (error) {
        console.error('Error parsing JSON in ChatMessageContent:', error, jsonString)
        setFollowupQuestions(null)
      }
    } else {
      setMessageContentWithoutFollowUps(message.content)
      setFollowupQuestions(null)
    }
  }, [message.content])

  const [isProd, setIsProd] = useState<boolean>(true)
  const { data: session } = useSession()
  const messageRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    setIsProd(!(session as any)?.user?.isCGN)
  }, [session])
  useEffect(() => {
    if (isLoading) {
      messageRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [isLoading])
  const annotations = message.annotations as MessageAnnotation[] | undefined
  if (!annotations?.length) return <Markdown content={message.content} />
  const sourceData = getAnnotationData<SourceData>(annotations, MessageAnnotationType.SOURCES)
  const contents: ContentDisplayConfig[] = [
    {
      order: 0,
      component: <Markdown content={messageContentWithoutFollowUps} />,
    },
    {
      order: 1,
      component: sourceData[0] ? <ChatTabSources data={sourceData[0]} /> : null,
    },
    {
      order: 2,
      component: followupQuestions ? <ChatFollowUpQuestions followUpQuestions={followupQuestions} /> : null,
    },
  ]
  return (
    <div ref={messageRef} className="flex-1 gap-4 flex flex-col">
      {contents
        .sort((a, b) => a.order - b.order)
        .map((content, index) => (
          <Fragment key={index}>{content.component}</Fragment>
        ))}
    </div>
  )
}
const ChatMessage = ({
  chatMessage,
  isLoading,
  noAvatarDisplay,
}: {
  chatMessage: Message
  isLoading: boolean
  noAvatarDisplay?: boolean
}) => {
  return (
    <div className={`flex items-start gap-4 pr-5 w-full pt-2`}>
      {!noAvatarDisplay ? (
        <ChatAvatar role={chatMessage.role} />
      ) : (
        <div className="flex flex-col items-center self-start">
          <div className="relative w-8 h-8"></div>
        </div>
      )}
      <div
        className={`group flex flex-1 justify-between gap-2 ${chatMessage.role === 'assistant' ? 'pt-0' : 'pt-3'}  break-keep`}
      >
        <ChatMessageContent message={chatMessage} isLoading={isLoading} />
      </div>
    </div>
  )
}
export default ChatMessage
